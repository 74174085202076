import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "./AddEvent.module.css";
import {
  CustomMultiSelectFields,
  CustomSelectFieldsModelForm,
} from "../CustomComponent/CustomInputFields/CustomInputField";
import IndependicareApi from "../../Helpers/Api";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Toster from "../../Toster/Toster";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import Loader from "../LoaderComponent/LoaderComponent";
import { CustomDatePicker } from "../CustomComponent/CustomInputFields/CustomInputField";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUserState } from "../../redux/slices/userSlice";
import { CustomSelectFieldsPredefined } from "../CustomComponent/CustomInputFields/CustomInputField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import CustomSearchField from "../CustomComponent/CustomSearchField/CustomSearchField";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
// dayjs-config.js (if needed)
import "dayjs/locale/en-gb";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { LIST_TYPE, MESSAGE_TYPE } from "../../Utils/Constant";
import useDebounce from "../../Helpers/Debounce";
import { debounce } from "lodash";
import { EVENT_TYPE } from "../../Utils/Constant";
import {
  convertUTCTimeToLocalTime,
  convertUTCToLocal,
} from "../../Utils/Common";
import moment from "moment";

dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

function AddEvent({
  showModal,
  handleCloseModal,
  pageNo,
  getEventList,
  eventItem,
  id,
  type,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState,
    setValue,
    // errors,
    control,
    formState: { errors },
    className,
    clearErrors,
    setError,
  } = useForm({
    mode: "onChange",
  });
  const { t } = useTranslation();
  const messageType = MESSAGE_TYPE.TIME_MESSAGE;
  const token = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);
  const [selectPerson, setSelectPerson] = useState("");
  // const [selectDate, setSelectDate] = useState(new Date());
  const [selectDate, setSelectDate] = useState(moment().toDate());
  const [updateID, setUpdateID] = useState("");

  const [eventTypeId, setEventTypeId] = useState("event");

  const [getPerson, setGetPerson] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [selectedLabelNames, setSelectedLabelNames] = useState([]);

  const [personOption, setPersonOption] = useState([]);
  const [person, setPerson] = useState([]);
  const [personName, setPersonName] = useState([]);

  const [getEvent, setGetEvent] = useState([]);
  const [eventOption, setEventOption] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");

  const [selectDevice, setSelectDevice] = useState("");
  const [selectedValue, setSelectedValue] = useState("predefined");

  const [timeMessage, setTimeMessage] = useState([]);
  const [messageOption, setMessageOption] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const [level, setLevel] = useState([]);
  const [levelOption, setLevelOption] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");

  // const [time, setTime] = useState(dayjs());
  const [time, setTime] = useState(dayjs().add(5, "minute"));

  const [schedule, setSchedule] = useState(
    dayjs().second(0).add(5, "minute").format("HH:mm:ss")
  );

  const [error, setErrors] = useState("");

  const [eventId, setEventId] = useState("");
  const [showValidation, setShowValidation] = useState(false);
  const [showPerson, setShowPerson] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [currentPageForPerson, setCurrentPageForPerson] = useState(1);
  const [addedCaregiverListCountPerson, setAddedCaregiverListCountPerson] =
    useState(0);
  const pageLimit = 10;

  const onPageChangePerson = (pageNumber) => {
    setCurrentPageForPerson(pageNumber);
    getPersonList(pageNumber, searchQuery);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClosePerson = () => {
    setSearchQuery("");
    setShowPerson(false);
  };
  const handleShowPerson = () => setShowPerson(true);

  const multiSelectRef = useRef(null);

  const listInnerRef = useRef();
  const [isLoadMore, setIsLoadMore] = useState(false);

  const handleScroll = () => {
    if (!isLoadMore) return;

    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        // onPageChangePerson(currentPageForPerson + 1);
        let roundedNumber = Math.ceil(
          addedCaregiverListCountPerson / pageLimit
        );
        if (currentPageForPerson >= roundedNumber) {
          setIsLoadMore(false);
        } else {
          onPageChangePerson(currentPageForPerson + 1);
        }
      }
    }
  };

  const handleTimeChange = (newValue) => {
    if (newValue && newValue.isValid()) {
      setErrors("");
      const formattedTime = newValue.second(0).format("HH:mm:ss");
      setTime(newValue);
      setSchedule(formattedTime);
    } else {
      setErrors(t("INVALID_TIME_FORMAT"));
    }
  };

  useEffect(() => {
    if (formState.isSubmitted && !selectedMessage) {
      setError("selectPredefined", {
        type: "manual",
        message: "PLEASE_SELECT_PREDEFINED_REMINDER_ID",
      });
    }
  }, [showModal, formState.isSubmitted]);

  // setValue for updations
  useEffect(() => {
    if (eventItem) {
      setEventId(eventItem.id);

      if (eventItem.reminder_type === "predefined") {
        setSelectedValue("predefined");
        setValue("selectPredefined", eventItem.reminder_id);
        setSelectedMessage({
          value: eventItem.reminder_id,
          label: eventItem.reminder,
        });
      }
      const editedTime = moment(
        convertUTCToLocal(eventItem?.time),
        "hh:mm:ss A"
      ).format("hh:mm A");
      const formattedTime = dayjs(editedTime, "hh:mm A").format("HH:mm:ss");

      const parsedTime = dayjs(eventItem.time, "HH:mm:ss").second(0);
      if (parsedTime.isValid()) {
        let timeValue = eventItem.time;
        dayjs(eventItem.time, "hh:mm:ss A");

        setTime(dayjs(formattedTime, "hh:mm:ss "));
        // setTime(dayjs(eventItem.time, "hh:mm:ss A"));

        setSchedule(formattedTime);
        setValue("time", parsedTime.format("HH:mm:ss A"));
      }

      setValue("eventType", eventItem.event_type_id);
      setSelectedEvent(eventItem.event_type_id);

      const dateString = eventItem.date;
      const parts = dateString.split("/");

      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);

      // const dateObject = new Date(year, month, day);
      // console.log(dateObject, "dateobj");
      // setSelectDate(dateObject);

      // Combine date and time into a single Date object
      const combinedDateTimeString = `${eventItem.date.split("T")[0]}T${
        eventItem.time
      }Z`; // Ensure you add 'Z' for UTC

      const combinedDateTime = moment.utc(combinedDateTimeString).toDate();
      // console.log(combinedDateTime, "combinedDateTime");

      setValue("date", combinedDateTime);

      if (eventItem.reminder_type === "custom") {
        setSelectedValue("custom");
        setValue("level", eventItem.custom_level);
        setValue("customSelect", eventItem.custom_reminder);
        setSelectedLevel(eventItem.custom_level);
      }

      if (eventItem.person_id) {
        const personIds = Array.isArray(eventItem.person_id)
          ? eventItem.person_id
          : eventItem.person_id.toString().split(",");

        setSelectedPerson(personIds);
        setPerson(personIds);
        const selectedNames = personOption
          .filter((option) => personIds.includes(option.value))
          .map((option) => option.label);

        const arr = eventItem?.person_names
          ?.split(",")
          .map((name) => name.trim());
        setPersonName([...arr]);
        setSelectedLabelNames([...arr]);

        setValue("person", personIds);
      } else {
        setSelectedPerson([]);
        setPerson([]);
      }
    }
  }, [eventItem]);

  const onSubmit = (data) => {
    const formData = new FormData();
    const formattedDate = data.date
      ? format(new Date(data.date), "yyyy-MM-dd")
      : "";

    formData.append("event_type", "event");
    formData.append("reminder_type", selectedValue);
    formData.append("date", formattedDate);
    formData.append("time", schedule);
    formData.append("event_type_id", selectedEvent);
    // formData.append("person_id", selectedPerson.join(","));

    const personIds = Array.isArray(person) ? person : [];
    formData.append("person_id", personIds.join(","));

    if (personIds.length == 0) {
      Toster("Please select at least one person", "error");
      return;
    }

    if (selectedValue === "predefined") {
      formData.append("reminder_id", selectedMessage?.value);

      formData.append("custom_level", "");
      formData.append("custom_reminder", "");
    }
    if (selectedValue === "custom") {
      formData.append("reminder_id", "");
      formData.append("custom_level", selectedLevel);
      formData.append("custom_reminder", data.customSelect);
    }
    if (!eventId) {
      setIsLoading(true);
      IndependicareApi.saveEvent(formData, token)
        .then((res) => {
          setIsLoading(false);
          const responseCode = STATUS_MSG[res?.data?.code];
          if (res?.code === STATUS_CODE.SUCCESS) {
            Toster(t("ADD_EVENT_SUCCESFULLY"), "success");
            resetForm();
            // setEventId("");
            handleCloseModal();
            getEventList();
          } else if (
            res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
            res?.data?.code === STATUS_CODE.INACTIVE_USER
          ) {
            dispatch(clearUserState());
            Toster(t("SESSION_EXPIRED"), "error");
            navigate("/signin");
          } else if (
            res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR ||
            res?.code === STATUS_CODE.THE_TIME_MUST_BE_CURRENT_OR_FUTURE_TIME
          ) {
            Toster(res?.data?.message, "error");
          } else {
            Toster(t(responseCode), "error");
          }
        })
        .catch((error) => {
          Toster(error, "error");
        });
    } else {
      setIsLoading(true);
      IndependicareApi.setEvent(formData, eventId, token)
        .then((res) => {
          setIsLoading(false);
          const responseCode = STATUS_MSG[res?.data?.code];
          if (res?.code === STATUS_CODE.SUCCESS) {
            Toster(t("UPDATE_EVENT_SUCCESFULLY"), "success");
            setEventId("");
            resetForm();
            handleCloseModal();
            getEventList();
          } else if (
            res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
            res?.data?.code === STATUS_CODE.INACTIVE_USER
          ) {
            dispatch(clearUserState());
            Toster(t("SESSION_EXPIRED"), "error");
            navigate("/signin");
          } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
            Toster(res?.data?.message, "error");
          } else {
            Toster(t(responseCode), "error");
          }
        })
        .catch((error) => {
          Toster(error, "error");
        });
    }
  };

  const debouncedSearchPerson = useDebounce((term) => {
    if (type == "caregiver") {
      getAssignPersonList(1, term);
    } else {
      getPersonList(1, term, 1);
    }
  }, 500);

  const getPersonList = (currentPageForPerson, searchQuery) => {
    IndependicareApi.getPersonList(
      token,
      currentPageForPerson,
      searchQuery,
      LIST_TYPE.ACTIVE
    ).then((res) => {
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        // setGetPerson(res?.data?.person_list);
        // setAddedCaregiverListCountPerson(res?.data?.count);

        const newPersons = res?.data?.person_list;
        if (currentPageForPerson === 1) {
          // If it's the first page, replace the list
          setGetPerson(newPersons);
        } else {
          // Otherwise, append new data to existing list
          setGetPerson((prevPersons) => [...prevPersons, ...newPersons]);
        }
        setAddedCaregiverListCountPerson(res?.data?.count);
        setIsLoadMore(res?.data?.loadMore);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
      } else {
        Toster(t(responseCode), "error");
      }
    });
  };

  const getAssignPersonList = async (currentPageForPerson, searchQuery) => {
    setIsLoading(true);
    IndependicareApi.getAssignPersonList(
      currentPageForPerson,
      searchQuery,
      token,
      id,
      "add_event"
    ).then((res) => {
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        // setGetPerson(res?.data?.person_list);
        // setAddedCaregiverListCountPerson(res?.data?.count);

        const newPersons = res?.data?.assignedSeniors;
        if (currentPageForPerson === 1) {
          // If it's the first page, replace the list
          setGetPerson(newPersons);
        } else {
          // Otherwise, append new data to existing list
          setGetPerson((prevPersons) => [...prevPersons, ...newPersons]);
        }
        // setAddedCaregiverListCountPerson(res?.data?.count);
        setIsLoadMore(res?.data?.loadMore);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
      } else {
        Toster(t(responseCode), "error");
      }
    });
  };

  useEffect(() => {
    type !== "caregiver" && getPersonList(currentPageForPerson, searchQuery);
    type == "caregiver" &&
      getAssignPersonList(currentPageForPerson, searchQuery);
  }, [showPerson]);

  useEffect(() => {
    if (type == "caregiver") {
      const options = getPerson.map((person) => ({
        value: person.person_id,
        label: person.person_name,
      }));
      setPersonOption(options);
    } else {
      const options = getPerson.map((person) => ({
        value: person.id,
        label: person.person_name,
      }));
      setPersonOption(options);
    }
  }, [getPerson]);

  const handleSubmitPerson = () => {
    // setPersonName([]);
    // // setPerson(selectedLabels);
    if (selectedPerson.length === 0) {
      setPersonName([]);
      setShowValidation(true);
      handleClosePerson();
    } else {
      setShowValidation(false);
      setPerson(selectedPerson);
      setPersonName(selectedLabelNames.join(","));
      handleClosePerson();
    }
    setSearchQuery("");
    setCurrentPageForPerson(1);
  };

  const handleChange = (event, label) => {
    const id = event.target.value;
    const checked = event.target.checked;
    setSelectedPerson((prev) =>
      checked ? [...prev, id] : prev.filter((item) => item !== id)
    );
    if (checked) {
      setSelectedLabelNames([...selectedLabelNames, label]);
    } else {
      const temparr = selectedLabelNames?.filter((item) => item != label);
      setSelectedLabelNames([...temparr]);
    }
  };

  const selectedLabels = personOption
    .filter((option) => selectedPerson.includes(option.value))
    .map((option) => option.label)
    .join(", ");

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // event type----------

  const handelGetData = () => {
    setIsLoading(true);
    IndependicareApi.fetchEvent(
      token,
      EVENT_TYPE.EVENTS,
      LIST_TYPE.ACTIVE,
      id
    ).then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (response?.code == STATUS_CODE.SUCCESS) {
        // setList(response?.data?.event_types);
        setGetEvent(response?.data?.event_types);
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    handelGetData();
  }, []);

  useEffect(() => {
    const options = [];
    if (getEvent.length > 0) {
      getEvent.map((event) => {
        if (event.is_active === 1) {
          options.push({ id: event.id, name: event.title });
        }
      });
      setEventOption(options);
    }
  }, [getEvent]);

  const SelectEventType = (e) => {
    setSelectedEvent(e.target.value);
    clearErrors("eventType");
  };

  //  level starts here-----------------

  const handelGetLevel = () => {
    setIsLoading(true);
    IndependicareApi.fetchLevel(token, LIST_TYPE.ACTIVE, id).then(
      (response) => {
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("session expired"), "error");
          navigate("/signin");
        } else {
          setLevel(response?.data?.level);
          setIsLoading(false);
        }
      }
    );
  };
  useEffect(() => {
    handelGetLevel();
  }, []);

  useEffect(() => {
    const options = [];
    if (level?.length > 0) {
      level.map((event) => {
        if (event.is_active === 1) {
          options.push({ id: event.id, name: event.level });
        }
      });
      setLevelOption(options);
    }
  }, [level]);
  const handleSelectLevel = (e) => {
    setSelectedLevel(e.target.value);
    clearErrors("level");
  };
  // level ends here

  const handleCustomOptions = (event) => {
    setSelectedValue(event.target.value);
  };

  // predefined field code starts
  const messageGetData = (messageType) => {
    setIsLoading(true);
    IndependicareApi.fetchMessage(
      token,
      messageType,
      LIST_TYPE.ACTIVE,
      id
    ).then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else {
        // setMsgTitle(response?.data?.message_type);
        setTimeMessage(response?.data?.message_type);
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    messageGetData(messageType);
  }, []);

  useEffect(() => {
    const options = [];
    if (timeMessage?.length > 0) {
      timeMessage.map((event) => {
        if (event.is_active === 1) {
          options.push({ id: event.id, name: event.title });
        }
      });
      setMessageOption(options);
    }
  }, [timeMessage]);

  const handleSelectPredefined = (selectedOption) => {
    setValue("selectPredefined", selectedOption.value);
    setSelectedMessage(selectedOption);
    clearErrors("selectPredefined");
  };

  const resetForm = () => {
    reset({
      eventType: "",
      customSelect: "",
      selectPredefined: "",
      personName: [],
    });

    setSelectedEvent("");
    setSelectedPerson([]);
    setPerson([]);
    setSelectDate(new Date());
    setSelectedMessage("");
    setSelectedValue("predefined");
    setPersonName([]);
    setSelectedLabelNames([]);
    setTime(dayjs().add(5, "minute"));
    setSchedule(dayjs().second(0).add(5, "minute").format("HH:mm:ss"));
    setEventId("");

    if (multiSelectRef.current) {
      if (typeof multiSelectRef.current.reset === "function") {
        multiSelectRef.current.reset();
      } else {
        multiSelectRef.current.value = "";
      }
    }
  };

  const isDisabled = () => {
    if (type == "caregiver" && eventId) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => {
          handleCloseModal();
          resetForm();
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title className={`setModelTitleProperty ${styles.modelTitle}`}>
            {eventId ? t("UPDATE_EVENT") : t("ADD_EVENT")}
          </Modal.Title>
          {/* <CancelIcon
            className="closeIconProperty"
            onClick={() => {
              handleCloseModal();
              resetForm();
            }}
          /> */}
          <OverlayTrigger
            placement="top" // You can adjust the position (top, bottom, left, right)
            overlay={<Tooltip id="cancel-tooltip">{t("CLOSE")}</Tooltip>} // Customize your tooltip message
          >
            <CancelIcon
              className="closeIconProperty"
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
            />
          </OverlayTrigger>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && <Loader />}

            <div className={`border-none ${styles.personField}`}>
              <Form.Group className="mb-3">
                <Form.Label className={styles.personLabel}>
                  {t("PERSON")}
                </Form.Label>
                <Form.Control
                  type="text"
                  // value={person}
                  value={personName}
                  placeholder={t("SELECT_PERSON")}
                  onClick={handleShowPerson}
                  readOnly
                  className={`${styles.addDeviceProperty} ${styles.inputStyle}`}
                  isInvalid={showValidation}
                  disabled={isDisabled()}
                />
                <Form.Control.Feedback type="invalid">
                  {t("PLEASE_SELECT_AT_LEAST_ONE_PERSON")}
                </Form.Control.Feedback>
              </Form.Group>
              <Modal show={showPerson} onHide={handleClosePerson}>
                <Modal.Header className="d-flex justify-content-between">
                  <Modal.Title className="setModelTitleProperty">
                    {t("SELECT_OPTIONS")}
                  </Modal.Title>
                  <CancelIcon
                    className="closeIconProperty"
                    onClick={handleClosePerson}
                  />
                </Modal.Header>
                <Modal.Body>
                  <Form.Group>
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-center">
                        <CustomSearchField
                          setSearchQuery={(val) => {
                            setSearchQuery(val);
                            debouncedSearchPerson(val);
                          }}
                        />
                      </div>
                      <div
                        className={styles.personListContainer}
                        ref={listInnerRef}
                        onScroll={debounce(handleScroll, 100)}
                      >
                        {personOption && personOption.length > 0 ? (
                          personOption.map((option) => (
                            <div
                              key={option.id}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <Form.Label htmlFor={`person-${option.value}`}>
                                {option.label}
                              </Form.Label>
                              <Form.Check
                                key={option.value}
                                type="checkbox"
                                id={`person-${option.value}`}
                                value={option.value}
                                checked={selectedPerson.includes(option.value)}
                                onChange={(e) => handleChange(e, option.label)}
                              />
                            </div>
                          ))
                        ) : (
                          <div className="d-flex justify-content-center p-2">
                            <p>{t("NO_RECORD_FOUND")}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    className="w-100"
                    onClick={handleSubmitPerson}
                  >
                    {t("Submit")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <CustomDatePicker
              control={control}
              RegisterName="date"
              register={register}
              formState={formState}
              label={t("DATE")}
              PlaceHolder={t("SELECT_DATE")}
              className={`${styles.addDeviceProperty} ${styles.textSize}`}
              defaultValue={selectDate}
              rules={{
                required: t("DATE_IS_REQUIRED"),
                validate: (value) => (value ? true : t("INVALID_DATE")),
              }}
            />

            <div className="clockIcom">
              <p className={styles.timeLabel}>{t("TIME")}</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  sx={{
                    width: "100%",
                    borderColor: "#E3E3E3",
                  }}
                  value={time}
                  onChange={handleTimeChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {error && <p className={styles.errorMessage}>{error}</p>}
            </div>

            <CustomSelectFieldsModelForm
              PlaceHolder={t("SELECT_EVENT_TYPE")}
              RegisterName="eventType"
              register={register}
              options={eventOption}
              formState={formState}
              label={t("EVENT_TYPE")}
              value={selectedEvent}
              onChange={SelectEventType}
              className={styles.addDeviceProperty}
            />

            {/* <div className={`border-none ${styles.personField}`}>
              <Form.Group className="mb-3">
                <Form.Label className={styles.personLabel}>
                  {t("PERSON")}
                </Form.Label>
                <Form.Control
                  type="text"
                  // value={person}
                  value={personName}
                  placeholder={t("SELECT_PERSON")}
                  onClick={handleShowPerson}
                  readOnly
                  className={`${styles.addDeviceProperty} ${styles.inputStyle}`}
                  isInvalid={showValidation}
                />
                <Form.Control.Feedback type="invalid">
                  {t("PLEASE_SELECT_AT_LEAST_ONE_PERSON")}
                </Form.Control.Feedback>
              </Form.Group>
              <Modal show={showPerson} onHide={handleClosePerson}>
                <Modal.Header className="d-flex justify-content-between">
                  <Modal.Title className="setModelTitleProperty">
                    {t("SELECT_OPTIONS")}
                  </Modal.Title>
                  <CancelIcon
                    className="closeIconProperty"
                    onClick={handleClosePerson}
                  />
                </Modal.Header>
                <Modal.Body>
                  <Form.Group>
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-center">
                        <CustomSearchField
                          setSearchQuery={(val) => {
                            setSearchQuery(val);
                            debouncedSearchPerson(val);
                          }}
                        />
                      </div>
                      <div
                        className={styles.personListContainer}
                        ref={listInnerRef}
                        onScroll={debounce(handleScroll, 100)}
                      >
                        {personOption && personOption.length > 0 ? (
                          personOption.map((option) => (
                            <div
                              key={option.id}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <Form.Label htmlFor={`person-${option.value}`}>
                                {option.label}
                              </Form.Label>
                              <Form.Check
                                key={option.value}
                                type="checkbox"
                                id={`person-${option.value}`}
                                value={option.value}
                                checked={selectedPerson.includes(option.value)}
                                onChange={(e) => handleChange(e, option.label)}
                              />
                            </div>
                          ))
                        ) : (
                          <div className="d-flex justify-content-center p-2">
                            <p>{t("NO_RECORD_FOUND")}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    className="w-100"
                    // onClick={handleClose}
                    onClick={handleSubmitPerson}
                  >
                    {t("Submit")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div> */}
            <div>
              <p className={styles.reminder}>{t("REMINDER")}</p>
              <Row>
                <Col>
                  <div
                    className={`${styles.noneContent} ${styles.radioContainer}`}
                  >
                    <label htmlFor="radio-1" className={styles.customLabel}>
                      {t("PREDEFINED")}
                    </label>
                    <Form.Check
                      type="radio"
                      id="radio-1"
                      name="radio-group"
                      value="predefined"
                      checked={selectedValue === "predefined"}
                      onChange={handleCustomOptions}
                      // label={t("PREDEFINED")}
                      defaultChecked
                    />
                  </div>
                </Col>
                <Col>
                  <div
                    className={`${styles.noneContent} ${styles.radioContainer}`}
                  >
                    <label htmlFor="radio-2" className={styles.customLabel}>
                      {t("CUSTOM")}
                    </label>
                    <Form.Check
                      type="radio"
                      id="radio-2"
                      name="radio-group"
                      value="custom"
                      checked={selectedValue === "custom"}
                      onChange={handleCustomOptions}
                      // label={t("CUSTOM")}
                    />
                  </div>
                </Col>
              </Row>
              {selectedValue === "predefined" && (
                <CustomMultiSelectFields
                  PlaceHolder={t("SELECT_PREDEFINED")}
                  RegisterName="selectPredefined"
                  register={register}
                  options={messageOption}
                  formState={formState}
                  label={t("")}
                  value={selectedMessage}
                  onChangeHandler={handleSelectPredefined}
                  isMulti={false}
                />
              )}
              {selectedValue === "custom" && (
                <>
                  <CustomSelectFieldsModelForm
                    PlaceHolder={t("SELECT_LEVEL")}
                    RegisterName="level"
                    options={levelOption}
                    register={register}
                    formState={formState}
                    label={t("LEVEL")}
                    value={selectedLevel}
                    onChange={handleSelectLevel}
                    className={`selectIcon ${styles.addDeviceProperty} ${styles.selectLevel}`}
                  />

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className={styles.textAreaLabel}>
                      {/* {t("NOTES")} */}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      style={{
                        height: "75px",
                        fontSize: "15px",
                        fontWeight: "400",
                        borderColor: errors.customSelect ? "red" : "#E3E3E3",
                      }}
                      placeholder={t("ENTER_SELECT")}
                      // className={`${styles.addSiteProperty}`}
                      className={`${styles.addSiteProperty} custom-textarea`}
                      // {...register("notes")}
                      {...register("customSelect", {
                        required: t("CUSTOM_REQUIRED"),
                        minLength: {
                          value: 2,
                          message: t("NOTES_TEXT_MINIMUM_2_CH"),
                        },
                        maxLength: {
                          value: 200,
                          message: t("CUSTOM_TEXT_NOT_MORE_THAN_200_CH"),
                        },
                        validate: {
                          noWhitespace: (value) =>
                            value.trim() !== "" ||
                            t("CUSTOM_TEXT_SHOULD_NOT_BE_WHITESPACES_ONLY"),
                        },
                      })}
                      isInvalid={!!errors.customSelect}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.customSelect?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="AddSiteSubmitButton" type="submit">
              {eventId ? t("UPDATE_EVENT") : t("ADD_EVENT")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
export default AddEvent;
