import { Image, Button, Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import IndependicareApi from "../../Helpers/Api";
import "bootstrap/dist/css/bootstrap.min.css";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "../../Components/Settings/Relation/Relation.module.css";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import Toster from "../../Toster/Toster";
import { clearUserState, userLogoutClear } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import deleteImage from "../../Assests/Images/delete_image.png";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

function UnlinkTagDeleteModal({
  showModal,
  handleCloseModal,
  UnlinkGetData,
  unlinkId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);

  const DeleteUnlinkTag = (unlinkId) => {
    setIsLoading(true);
    IndependicareApi.deleteUnlinkTagApi(token, unlinkId).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data.code];

      if (response?.code == STATUS_CODE.SUCCESS) {
        Toster(t("UNIQUE_ID_TAG_DELETED_SUCCESSFULLY"), "success");
        UnlinkGetData();
        handleCloseModal();
      } else if (
        response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
        response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
      ) {
        Toster(response?.code?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
      handleCloseModal();
    });
  };
  return (
    <>
      {/*=========delete Relation-Modal==============*/}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          {/* <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseModal}
          /> */}

          <OverlayTrigger
            placement="top" // You can adjust the position (top, bottom, left, right)
            overlay={<Tooltip id="cancel-tooltip">{t("CLOSE")}</Tooltip>} // Customize your tooltip message
          >
            <CancelIcon
              className="closeIconProperty"
              onClick={handleCloseModal}
            />
          </OverlayTrigger>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            <Image src={deleteImage} />

            <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_UNIQUE_ID_TAG")}</p>
          </div>

          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={() => {
              DeleteUnlinkTag(unlinkId);
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UnlinkTagDeleteModal;
