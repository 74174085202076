import React, { useState, useEffect } from "react";
import Styles from "./Notification.module.css";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IoNotifications } from "react-icons/io5";
import moment from "moment";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import IndependicareApi from "../../Helpers/Api";
import { userLogoutClear } from "../../redux/slices/userSlice";
import Toster from "../../Toster/Toster";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import useDebounce from "../../Helpers/Debounce";
import { Button } from "react-bootstrap";
import Confirm from "../../Common/Confirm";

function NotificationPage() {
  const { userToken, currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userType = localStorage.getItem("usertype");
  const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [loadMore, setLoadMore] = useState(false);
  const [pageNumberList, setPageNumberList] = useState(1);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [showBadge, setShowBadge] = useState(false);

  const [titleText, setTitleText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);

  //========== function for call notification =========
  useEffect(() => {
    callNotificationList();
  }, [show]);

  useEffect(() => {
    const isAllread = notificationData.every((val) => val.is_read == 1);
    if (isAllread) {
      setShowBadge(false);
    } else {
      setShowBadge(true);
    }
  }, [notificationData]);

  //========== function for call notification list =========
  const callNotificationList = (isLoadMore = false) => {
    let pageNo = pageNumberList;
    if (isLoadMore === true) {
      setPageNumberList((preState) => preState + 1);
      pageNo = pageNo + 1;
    }
    IndependicareApi.fetchNotificationList(userToken, pageNo).then(
      (response) => {
        const responseCode = STATUS_MSG[response?.data?.code];
        setIsLoading(false);
        if (
          response?.code == STATUS_CODE.SUCCESS ||
          response?.data?.code == STATUS_CODE.SUCCESS
        ) {
          setTotalCount(response?.data?.totalCount);
          setLoadMore(response?.data?.loadMore);
          if (isLoadMore == true) {
            setNotificationData([
              ...notificationData,
              ...response.data.notification_list,
            ]);
          } else {
            setNotificationData(response.data.notification_list);
          }
        } else if (
          response?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else if (
          response &&
          response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response && response.data.message, "error");
        } else if (
          response &&
          response?.data &&
          response?.data?.code === STATUS_CODE.FAILED_TO_ACCESS
        ) {
          Toster(t(responseCode), "error");
          dispatch(userLogoutClear());
        } else {
          Toster(t(responseCode), "error");
        }
      }
    );
  };

  const handleRedirect = (action, id) => {
    switch (action) {
      case "added-caregiver":
        navigate("/stackholder-detail", {
          state: { itemId: id },
        });
        break;
      case "person-assign":
      case "event":
      case "daily-event":
      case "sent-event":
      case "sent-daily-event":
        navigate(`/assignperson`);
        break;
      default:
        break;
    }
  };

  const handleRedirectStakeholder = (action, id) => {
    switch (action) {
      case "event":
      case "sent-event":
        navigate(`/event`);
        break;
      case "daily-event":
      case "sent-daily-event":
        navigate(`/daily-event`);
        break;
      default:
        break;
    }
  };

  //============ function for handle notification status===============
  const handleChangeStatus = (status_id) => {
    let res = IndependicareApi.readNotificationApi(userToken, status_id);
    res.then((data) => {
      if (
        data.code == STATUS_CODE.INVALID_TOKEN ||
        data.data.code == STATUS_CODE.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else {
        const updatedNotifications = notificationData.map((notification) => {
          if (notification.id === status_id) {
            return { ...notification, is_read: 1 };
          }
          return notification;
        });
        setNotificationData(updatedNotifications);
      }
    });
  };

  // =======function for notification delete api=======
  const notificationDelete = () => {
    IndependicareApi.deleteNotificationApi(userToken).then((responsejson) => {
      const ResponseCode =
        STATUS_MSG[
          (responsejson && responsejson.code) ||
            (responsejson && responsejson.data.code)
        ];
      if (responsejson.code === STATUS_CODE.SUCCESS) {
        Toster(t("DELETE_NOTIFICATION_SUCCUSS"), "success");
        setLoadMore(false);
        setPageNumberList(1);
        callNotificationList(1);
        setIsShowConfirm(false);
      } else if (responsejson.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(responsejson.code, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  const debouncedList = useDebounce((term) => {
    callNotificationList(term);
  }, 500);

  const handleRespondClick = (id) => {
    IndependicareApi.respondToNotification(userToken, id).then(
      (responsejson) => {
        const ResponseCode =
          STATUS_MSG[
            (responsejson && responsejson.code) ||
              (responsejson && responsejson.data.code)
          ];
        if (responsejson.code === STATUS_CODE.SUCCESS) {
          Toster(t("Responded successfully"), "success");
          callNotificationList();
        } else if (responsejson.code === STATUS_CODE.VALIDATIONS_ERROR) {
          Toster(responsejson.code, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    );
  };

  const handleNotifyClick = (id) => {
    IndependicareApi.notifyToCaregiver(userToken, id).then((responsejson) => {
      const ResponseCode =
        STATUS_MSG[
          (responsejson && responsejson.code) ||
            (responsejson && responsejson.data.code)
        ];
      if (responsejson.code === STATUS_CODE.SUCCESS) {
        Toster(t("Notified successfully"), "success");
      } else if (responsejson.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(responsejson.code, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  const handleDeleteShow = () => {
    setTitleText(t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALL_NOTIFICATION"));
    setIsDeleting(true);
    setIsShowConfirm(true);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    {
      isDeleting && notificationDelete();
    }
  };

  return (
    <div>
      <div className={Styles.topIcon}>
        <div className={Styles.notificationSection}>
          {showBadge && <div className={Styles.notificationCount}></div>}
          <IoNotifications
            className={Styles.notificationImg}
            ref={target}
            onClick={(event) => {
              setTarget(event.target);
              setPageNumberList(1);
              setShow(!show);
            }}
          />
        </div>
        <Overlay
          show={show}
          target={target}
          placement="bottom"
          rootClose={true}
          containerPadding={20}
          onHide={() => setShow(false)}
        >
          <Popover id="popover-contained">
            <div className={Styles.notificationSec}>
              {notificationData.length > 0 ? (
                <div className={Styles.clrBtn}>
                  <Button
                    size="xsm"
                    variant="danger"
                    className={`${Styles.responseBtn} ${Styles.btnresponse}`}
                    onClick={() => handleDeleteShow()}
                  >
                    {t("CLEAR_ALL")}
                  </Button>
                </div>
              ) : (
                ""
              )}
              <Popover.Body
                onScroll={(e) => {
                  const element = e.target;

                  if (
                    Math.abs(
                      element.scrollHeight -
                        element.scrollTop -
                        element.clientHeight
                    ) <= 1 &&
                    notificationData.length > 0 &&
                    loadMore == true
                  ) {
                    debouncedList(true);
                  }
                }}
              >
                {isLoading ? (
                  <div className={Styles.loadingMessage}>Loading...</div>
                ) : notificationData.length > 0 ? (
                  <div className="mt-3">
                    {notificationData.map((notification) => {
                      const hours = moment(notification?.created_at).fromNow();
                      return (
                        <>
                          <div
                            key={notification.id}
                            className={
                              notification.is_read !== 1
                                ? Styles.NotificationUnread
                                : Styles.NotificationRead
                            }
                          >
                            <div
                              onClick={() => {
                                if (notification.is_read !== 1) {
                                  handleChangeStatus(notification.id);
                                }
                                userType == "caregiver"
                                  ? handleRedirect(
                                      notification.action,
                                      notification.type_id
                                    )
                                  : handleRedirectStakeholder(
                                      notification.action,
                                      notification.type_id
                                    );
                                setShow(false);
                              }}
                            >
                              <h5>{notification.title}</h5>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    notification.description
                                  ),
                                }}
                                style={{ wordBreak: "break-word" }}
                              ></div>
                              <p>{hours}</p>
                            </div>
                            <div className={Styles.actionBtns}>
                              {/* <div>
                              <HighlightOffRoundedIcon
                                className={Styles.deleteIcon}
                                onClick={() =>
                                  notificationDelete(notification.id)
                                }
                              />
                            </div> */}
                              {userType == "caregiver" ? (
                                (notification.type === "event" ||
                                  notification.type === "daily-event" ||
                                  notification.type === "tag" ||
                                  notification.type === "device") &&
                                notification.is_reply == 0 && (
                                  <div>
                                    <Button
                                      size="xsm"
                                      variant="primary"
                                      className={Styles.responseBtn}
                                      onClick={() =>
                                        handleRespondClick(notification.id)
                                      }
                                    >
                                      Respond
                                    </Button>
                                  </div>
                                )
                              ) : (notification.type === "event" ||
                                  notification.type === "daily-event" ||
                                  notification.type === "tag" ||
                                  notification.type === "device") &&
                                notification.is_reply == 0 ? (
                                <div>
                                  <Button
                                    size="xsm"
                                    variant="primary"
                                    className={Styles.responseBtn}
                                    onClick={() =>
                                      handleNotifyClick(notification.id)
                                    }
                                  >
                                    Notify
                                  </Button>
                                </div>
                              ) : (
                                <div className={Styles.respondedTxt}>
                                  Responded
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <div className={Styles.noResultsMessage}>
                    {t("NO_NOTIFICATION")}
                  </div>
                )}
              </Popover.Body>
            </div>
          </Popover>
        </Overlay>
      </div>
      <Confirm
        isShowConfirm={isShowConfirm}
        setIsShowConfirm={setIsShowConfirm}
        titleText={titleText}
        setTitleText={setTitleText}
        isDeleting={isDeleting}
        setIsDeleting={setIsDeleting}
        isLoading={isLoading}
        handleConfirm={handleConfirm}
      />
    </div>
  );
}

export default NotificationPage;
