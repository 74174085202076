import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import IndependicareApi from "../../../Helpers/Api";
import { Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "../../../Components/Settings/TimeMessage/Message.module.css";
import { CustomSelectFields } from "../../CustomComponent/CustomInputFields/CustomInputField";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { STATUS_MSG } from "../../../Utils/StatusMessage";
import Toster from "../../../Toster/Toster";
import {
  clearUserState,
  userLogoutClear,
} from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { ACTIVE_TYPE, MESSAGE_TYPE } from "../../../Utils/Constant";
import { CustomInputFields } from "../../CustomComponent/CustomInputFields/CustomInputField";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
export const levelOption = Array.from({ length: 50 }, (_, i) => ({
  value: `value${i + 1}`,
  name: (i + 1).toString(),
}));

function TimeMessageModal({
  showModal,
  handleCloseModal,
  messageGetData,
  isEdit,
  messageTypeId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [levelId, setLevelId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [list, setList] = useState([]);
  const [optionList, setOptionList] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  const handelGetData = () => {
    setIsLoading(true);
    IndependicareApi.fetchLevel(token).then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("session expired"), "error");
        navigate("/signin");
      } else {
        setList(response?.data?.level);
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    let levelOption = [];
    list.map((item) => {
      if (item.is_active == ACTIVE_TYPE.IS_ACTIVE) {
        levelOption.push({ id: item.id, name: item.level });
      }
    });
    setOptionList(levelOption);
  }, [list]);

  {
    /*=============Add-message=========*/
  }
  const onSubmit = (data) => {
    const PostData = new FormData();
    PostData.append("message_type", MESSAGE_TYPE.TIME_MESSAGE);
    PostData.append("title", data.timeMessage);
    PostData.append("level", data.level);
    setIsLoading(true);
    if (!isEdit) {
      IndependicareApi.timeMessage(PostData, token).then((response) => {
        const ResponseCode = STATUS_MSG[response?.data.code];
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("session expired"), "error");
          navigate("/signin");
        } else {
          if (response?.code == STATUS_CODE.SUCCESS) {
            Toster(t("TIME_MESSAGE_ADDED_SUCCESSFULLY"), "success");
            messageGetData(MESSAGE_TYPE.TIME_MESSAGE);
            handelGetData();
            handleCloseModal();
            reset();
            setIsLoading(false);
          } else if (
            response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
            response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
          ) {
            Toster(t("VALIDATIONS_ERROR"), "error");
            setIsLoading(false);
            handleCloseModal();
          } else {
            Toster(t(ResponseCode), "error");
            setIsLoading(false);
          }
        }
      });
    } else {
      IndependicareApi.updateTimeMessage(PostData, token, messageTypeId).then(
        (response) => {
          setIsLoading(true);
          const ResponseCode = STATUS_MSG[response?.data.code];
          if (response?.code == STATUS_CODE.SUCCESS) {
            Toster(t("TIME_MESSAGE_UPDATE_SUCCESSFULLY"), "success");
            messageGetData(MESSAGE_TYPE.TIME_MESSAGE);
            handelGetData();
            handleCloseModal();
            reset();
          } else if (
            response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
            response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
          ) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      );
    }
  };
  useEffect(() => {
    handelGetData();
  }, []);

  const onChangeHandler = (e) => {
    setLevelId(e.target.value);
  };

  useEffect(() => {
    if (isEdit && messageTypeId) {
      IndependicareApi.detailMessage(token, messageTypeId).then((response) => {
        setValue("timeMessage", response?.data[0]?.title);
        setValue("level", response?.data[0]?.level_id);
      });
    } else {
      setValue("timeMessage", "");
      setValue("level", "");
      clearErrors("timeMessage", "");
      clearErrors("level", "");
    }
  }, [isEdit, messageTypeId, showModal]);

  return (
    <>
      {/*=========Add and update Time-Message==============*/}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("TIME_MESSAGE")}</p>
          {/* <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseModal}
          /> */}

          <OverlayTrigger
            placement="top" // You can adjust the position (top, bottom, left, right)
            overlay={<Tooltip id="cancel-tooltip">{t("CLOSE")}</Tooltip>} // Customize your tooltip message
          >
            <CancelIcon
              className="closeIconProperty"
              onClick={handleCloseModal}
            />
          </OverlayTrigger>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CustomInputFields
              type="text"
              PlaceHolder={t("ENTER_TIME_MESSAGE")}
              RegisterName="timeMessage"
              register={register}
              formState={formState}
              label={t("TIME_MESSAGE")}
              setValue={setValue}
            />

            <CustomSelectFields
              PlaceHolder={t("SELECT_LEVEL")}
              RegisterName="level"
              register={register}
              options={optionList}
              formState={formState}
              label={t("LEVEL")}
              onChangeHandler={onChangeHandler}
            />
            <Button type="submit" className="AddSiteSubmitButton">
              {isEdit ? t("UPDATE") : t("DONE")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default TimeMessageModal;
