import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Loader from "../../LoaderComponent/LoaderComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import IndependicareApi from "../../../Helpers/Api";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Toster from "../../../Toster/Toster";
import styles from "../LocationType/LocationType.module.css";
import { STATUS_MSG } from "../../../Utils/StatusMessage";
import {
  CustomInputFields,
  CustomSelectFields,
} from "../../CustomComponent/CustomInputFields/CustomInputField";
import CancelIcon from "@mui/icons-material/Cancel";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
const AddSubLocationTypeModel = ({
  show,
  handleClose,
  isEdit,
  locationId,
  setLocationId,
  handleGetData,
  searchTerm,
  formattedOptions,
}) => {
  const userToken = useSelector((state) => state.user.userToken);
  const { t } = useTranslation();
  const { register, handleSubmit, reset, formState, setValue, clearErrors } =
    useForm({
      mode: "onChange",
    });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [updateID, setUpdateID] = useState("");

  const addSubLocationType = (postData) => {
    IndependicareApi.addSubLocation(userToken, postData).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        if (response?.code == STATUS_CODE.SUCCESS) {
          Toster(t("SUB_LOCATION_ADDED_SUCCESSFULLY"), "success");
          reset();
          handleGetData(searchTerm, "");
          handleModalClose();
        } else if (
          response?.code == STATUS_CODE.VALIDATIONS_ERROR ||
          response?.data?.code == STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response?.code?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const updateSubLocationType = (postData) => {
    IndependicareApi.updateSubLocationType(
      userToken,
      postData,
      locationId
    ).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        if (response?.code == STATUS_CODE.SUCCESS) {
          Toster(t("SUB_LOCATION_UPDATED_SUCCESSFULLY"), "success");
          reset();
          handleGetData(searchTerm, "");
          handleModalClose();
        } else if (
          response?.code == STATUS_CODE.VALIDATIONS_ERROR ||
          response?.data?.code == STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response?.code?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const postData = new FormData();
    postData.append("title", data.locationName);
    {
      isEdit
        ? postData.append("location_id", updateID)
        : postData.append("location_id", locationId);
    }
    !isEdit ? addSubLocationType(postData) : updateSubLocationType(postData);
  };

  useEffect(() => {
    if (isEdit && locationId) {
      IndependicareApi.fetchSubLocationDetail(userToken, locationId).then(
        (response) => {
          setValue(
            "locationName",
            response?.data?.sublocation_type?.title || ""
          );
          setValue(
            "locationType",
            response?.data?.sublocation_type?.location_type_id || ""
          );
          setUpdateID(response?.data?.sublocation_type?.location_type_id || "");
          setLocationId(response?.data?.sublocation_type?.id || "");

          clearErrors("title");
        }
      );
    } else {
      setValue("title", "");
      clearErrors("title", "");
    }
  }, [isEdit, show]);

  const handleModalClose = () => {
    handleClose();
    reset();
  };

  const onChangeHandler = (e) => {
    setValue("locationType", e.target.value);
    clearErrors("locationType", "");
    !isEdit && setLocationId(e.target.value);
    isEdit && setUpdateID(e.target.value);
  };

  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      backdrop="static"
      keyboard={false}
    >
      {isLoading && <Loader />}
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title>
          {isEdit ? t("UPDATE_SUB_LOCATION") : t("ADD_SUB_LOCATION")}
        </Modal.Title>
        {/* <CancelIcon className="closeIconProperty" onClick={handleModalClose} /> */}
        <OverlayTrigger
          placement="top" // You can adjust the position (top, bottom, left, right)
          overlay={<Tooltip id="cancel-tooltip">{t("CLOSE")}</Tooltip>} // Customize your tooltip message
        >
          <CancelIcon
            className="closeIconProperty"
            onClick={handleModalClose}
          />
        </OverlayTrigger>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <CustomSelectFields
            PlaceHolder={t("SELECT_LOCATION")}
            RegisterName="locationType"
            register={register}
            options={formattedOptions}
            formState={formState}
            label={t("Select Location")}
            onChangeHandler={onChangeHandler}
          />
          <CustomInputFields
            type="text"
            PlaceHolder={t("ENTR_SUB_LOCATION")}
            RegisterName="locationName"
            register={register}
            formState={formState}
            label={t("SUB_LOCATION")}
            setValue={setValue}
          />
        </Modal.Body>
        <Modal.Footer className={styles.addNewTitle}>
          <Button variant="" type="submit">
            {/* {t("DONE")} */}
            {isEdit ? t("UPDATE") : t("DONE")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddSubLocationTypeModel;
